import {
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  ErrorOutline as ErrorOutlineIcon,
  InfoOutlined as InfoOutlinedIcon,
  WarningAmberOutlined as WarningAmberOutlinedIcon,
} from '@mui/icons-material';

export const messageTypes = {
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
};

export const buttonVariants = {
  primary: 'button-primary',
  secondary: 'button-secondary',
  clear: 'button-clear',
  success: 'button-success',
  dark: 'button-dark',
  danger: 'button-danger',
  warning: 'button-warning',
};

export const buttonWidths = {
  sm: 'button-small',
  md: 'button-medium',
  lg: 'button-large',
  full: 'full-width'
};

export const dataGridBulkEditButtonTypes = {
  email: 'email',
  edit: 'edit',
  delete: 'delete',
};

export const textLinkVariants = {
  primary: 'text-link-primary',
  secondary: 'text-link-secondary',
  clearDimmed: 'text-link-clear-dimmed',
  clear: 'text-link-clear',
};

export const noAccessMessageTypes = {
  noContentRepositories: 'noRepos',
};

export const noticeTypes = {
  error: 'error',
  success: 'success',
  info: 'info',
  warning: 'warning',
};
