export const downloadBlobFile = (blob, filename) => {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
};

export const downloadFromUrl = (downloadUrl, fileName = '', newTab = false) => {
  const a = document.createElement('a');
  a.style.display = 'none'
  a.setAttribute('href', downloadUrl);
  a.setAttribute('download', fileName);

  if (newTab) {
    a.setAttribute('target', '_blank');
  }

  document.body.appendChild(a);
  a.click();
  a.remove()
};

export const downloadFromRemoteUrl = async (downloadUrl, filename) => {
  try {
    const response = await fetch(downloadUrl);
    const blob = await response.blob();

    downloadBlobFile(blob, filename);
  } catch (error) {
    console.error(`Error downloading file from ${downloadUrl}`, error);

    return error;
  }
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.toString());
    reader.onerror = (error) => reject(error);
  });
};

export const extensionToText = {
  '.pptx': 'PPTX',
  '.ppt': 'PPT',
  '.pdf': 'PDF',
  '.xls': 'Excel',
  '.docx': 'Word',
  '.msg': 'Outlook Message',
  '.otf': 'Font',
  '.jpg': 'Image',
  '.jpeg': 'Image',
  '.png': 'Image',
  '.gif': 'Image',
};

export const compareIdentifiers = (first, second) => {
  const firstType = typeof first;
  const secondType = typeof second;

  if (firstType === secondType) {
    return first === second;
  }

  if (firstType === 'string' && secondType === 'number') {
    return first === second.toString();
  }

  if (firstType === 'number' && secondType === 'string') {
    return first === Number(second);
  }

  return false;
};
