export const TOOL_SM_SLUG = 'sm';
export const TOOL_QC_SLUG = 'qc';
export const TOOL_OE_SLUG = 'oe';
export const TOOL_OEKIT_SLUG = 'oekit';
export const TOOL_CM_SLUG = 'cm';
export const TOOL_NG_SLUG = 'ng';
export const TOOL_ADMIN_SLUG = 'admin';
export const TOOL_PGA_SLUG = 'pga';
export const TOOL_PG_SLUG = 'pg';
export const TOOL_MSM_SLUG = 'msm';
export const TOOL_SL_SLUG = 'sl';
export const TOOL_PM_SL_SLUG = 'pm-sl';


export const getActiveToolsWithLabels = (systemTools) => {
  const mappedSystemTools = (systemTools || []).map(toolItem => ({
    name: toolItem.name,
    slug: toolItem.slug,
  }))

  const pgIndex = mappedSystemTools.findIndex(item => item.slug === 'pg');
  const slIndex = mappedSystemTools.findIndex(item => item.slug === 'sl');

  // if env has both PM and SL enabled
  // combine them into one entry
  if(pgIndex !== -1 && slIndex !== -1) {
    const pgSlTool = {
      name: `${mappedSystemTools[pgIndex].name} / ${mappedSystemTools[slIndex].name}`,
      slug: `pm-${mappedSystemTools[slIndex].slug}` // this is to match asset usage slugs...
    };

    mappedSystemTools.splice(pgIndex, 1, pgSlTool);
    mappedSystemTools.splice(slIndex, 1)
  }

  return mappedSystemTools;
}
