import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from '../Tooltip/Tooltip';
import { IconButton } from '@mui/material'

export const StyledIconButton2 = styled(IconButton)`
  &:hover {
    opacity: 0.7;
    color: ${({ $hoverColor }) => $hoverColor || 'inherit'};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const IconButton2 = React.forwardRef(
  (
    {
      children,
      disabled,
      onClick,
      tooltip,
      hoverColor,
      type = 'button',
      ...dataAttributes
    },
    ref,
  ) => {
    const handleClick = (event) => {
      event.stopPropagation();
      onClick(event);
    };

    const renderIconButton = () => (
      <StyledIconButton2
        {...dataAttributes}
        $hoverColor={hoverColor}
        disabled={disabled}
        type={type}
        onClick={handleClick}
        ref={ref}
        disableRipple
      >
        {children}
      </StyledIconButton2>
    );

    if (tooltip) {
      return <Tooltip text={tooltip}>{renderIconButton()}</Tooltip>;
    }

    return renderIconButton();
  },
);

IconButton2.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  identifier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tooltip: PropTypes.string,
};

export default IconButton2;
